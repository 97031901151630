import UI_COLORS from '@jobber-project/jobber-ui/dist/shared/colors.umd'

// https://chir.ag/projects/name-that-color/#6B53FF

export const COLORS = {
  primary: '#000000',
  secondary: '#f2f2f2',
  white: '#ffffff',
  black: '#000000',
  nero: '#222222',
  gray: '#7f7f7f',
  whisper: '#e5e5e5',
  paleSlate: '#c4c4c4',
  iron: '#cccccc',
  hintOfRed: '#f9f9f9',
  red: '#eacfcf',
  green: '#d0eacf',
  alabster: '#fcfcfc',
  zircon: '#e1e1e1',
  matrix: '#bd5050',
  pancho: '#deb494',
  pistachio: '#6dd669',
  royalBlue: '#4666e1',
  regentGray: '#939da8',
  selago: '#F5F6FD',
  cornflowerBlueAlt: '#6453ff',
  indigo: '#4177c8',
  ...UI_COLORS,
}

/**
 * Convert an HEX color string to RGBA
 * @param {string} hex - HEX color code
 * @param {Number} alpha - Alpha number
 * @returns {string} - RGBA color
 */
export function rgba(hex, alpha = 1) {
  const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16))
  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}

export const LIGHT_THEME = {
  name: 'light',
  text: {
    primary: COLORS.nero,
  },
}

export const DARK_THEME = {
  name: 'dark',
  text: {
    primary: COLORS.white,
  },
}
