import { i18n } from 'next-i18next'

import { ERROR_CODE_MAP } from '../features/app/constants'

/**
 * Get an error message from an error
 * @param err Error object
 * @param fallback Fallback message string
 * @returns Error message or fallback string
 */
export function getErrorMessage(err: Error, fallback?: string): string {
  return err.message || fallback || null
}

export function getTranslatedErrorMessage(code: string): string | null {
  if (ERROR_CODE_MAP[code]) {
    return i18n.t(`error.${ERROR_CODE_MAP[code]}`)
  }

  return null
}
