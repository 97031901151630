import invert from 'lodash.invert'

export const GRID_COLUMNS = 12

export const GRID_WIDTH = 1140

export const PAGINATION_LIMIT = 20

export enum BREAKPOINT {
  small = 0,
  medium = 700,
  large = 980,
}

export const REGEX = {
  email: /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/,
  password: /^(?=.*?[A-Ö])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$/,
}

export const PAGE_TITLES = {
  landing: 'Workity',
  auth: 'Autentisering',
  profile: 'Profil',
}

export const FIREBASE_ERROR_CODE = {
  existsDifferentCredential: 'auth/account-exists-with-different-credential',
  userNotFound: 'auth/user-not-found',
  wrongPassword: 'auth/wrong-password',
  invalidEmail: 'auth/invalid-email',
}

export const GENDER_TYPES = {
  male: 'M',
  female: 'F',
  other: null,
}

export const MONTH = {
  january: 'january',
  february: 'february',
  mars: 'mars',
  april: 'april',
  may: 'may',
  june: 'june',
  july: 'july',
  august: 'august',
  september: 'september',
  october: 'october',
  november: 'november',
  december: 'december',
}

export const MONTH_ARRAY = Object.values(MONTH)

export const KEY_CODE = {
  enter: 13,
  space: 32,
}

export enum EXPERIENCE_TYPE {
  experience = 'Experience',
  education = 'Education',
  otherQualifications = 'otherQualifications',
}

export const SECTION_TYPE = {
  experience: 'Experience',
  education: 'Education',
  otherQualifications: 'OtherQualifications',
  tag: 'Tag',
}

export const RESUME_TEMPLATE = {
  default: 'default',
}

export enum DIALOG_BUTTON_STYLE {
  default = 'default',
  cancel = 'cancel',
  destructive = 'destructive',
}

export const TAG_TITLE = {
  certificates: 'Certifikat',
  language: 'Språkkunskap',
  it: 'IT-kunskap',
  other: 'Annat',
}

export const LANGUAGE_KNOWLEDGE = {
  1: 'Grundläggande kunskaper',
  2: 'Goda kunskaper',
  3: 'Avancerade kunskaper',
  4: 'Flytande',
  5: 'Modersmål',
}

export const IT_KNOWLEDGE = {
  1: 'Grundläggande kunskaper',
  2: '',
  3: 'Goda kunskaper',
  4: '',
  5: 'Avancerade kunskaper',
}

export const LANGUAGE_OPTIONS = [
  { value: 5, label: LANGUAGE_KNOWLEDGE[5], id: '5' },
  { value: 4, label: LANGUAGE_KNOWLEDGE[4], id: '4' },
  { value: 3, label: LANGUAGE_KNOWLEDGE[3], id: '3' },
  { value: 2, label: LANGUAGE_KNOWLEDGE[2], id: '2' },
  { value: 1, label: LANGUAGE_KNOWLEDGE[1], id: '1' },
]

export const IT_OPTIONS = [
  { value: 5, label: IT_KNOWLEDGE[5], id: '5' },
  { value: 3, label: IT_KNOWLEDGE[3], id: '3' },
  { value: 1, label: IT_KNOWLEDGE[1], id: '1' },
]

export const ROLE_TYPE = {
  coach: 'Coach',
  candidate: 'Candidate',
  user: 'User',
}

export const ERROR_CODE = {
  // Coach
  userAlreadyInvited: 'ad2fff94-24ba-401e-8a0a-0f56a9c869fe',
  cannotInviteCoach: 'b8d11324-a336-49d2-8511-61a915623ab1',
  featureNotEnabled: '474d2afa-58a8-47a2-823c-197434b40db8',
  maximumGenerationsExceeded: 'f1f83783-ba41-429e-8f75-af291f53d6d7',
  couldNotRemoveCoach: '7eb22781-ad3b-4ffb-b718-569d7b23e7b9',
  userIsAlreadyACandidate: '9a21bd53-f76e-44bb-82ba-7a02df21ceb6',
  profanity: '5b0a439f-8a5c-4270-8d17-e7ad2986f194',
  completionInputTooLarge: 'f40573f4-14e3-4533-b123-ebd43ed33650',
}

export const ERROR_CODE_MAP = invert(ERROR_CODE)

export const INVITATION_RESPONSE = {
  pending: 'Pending',
  accepted: 'Accepted',
  rejected: 'Rejected',
}

export enum CANDIDATE_STATUS {
  notStarted = 'Not started',
  inProgress = 'In progress',
  rejected = 'Rejected',
  done = 'Done',
  cancelled = 'Cancelled',
}

export const ENVIRONMENT = {
  dev: 'dev',
  production: 'production',
}

export const ANALYTICS_ID = {
  [ENVIRONMENT.dev]: 'G-SGW52VL2CF',
  [ENVIRONMENT.production]: 'G-QV3BR5KQGC',
}

export const INVITATION_URL = '/auth/register?email='
