import {
  ApolloClient,
  ApolloLink,
  from,
  // HttpLink /* split */,
} from '@apollo/client'
import type { User } from 'firebase/auth'
import { setContext } from '@apollo/client/link/context'
import { createUploadLink } from 'apollo-upload-client'
// import { getMainDefinition } from '@apollo/client/utilities'
// import { WebSocketLink } from '@apollo/client/link/ws'

import { auth } from '../firebase'
import { cache } from '../cache/cache'
import { getTranslatedErrorMessage } from '../../utils/errorUtils'

const httpLink = createUploadLink({
  uri: process.env.NEXT_PUBLIC_API_URL,
})

// const wsLink = process.browser
//   ? new WebSocketLink({
//       uri: `ws://dev-jobber-project.herokuapp.com/graphql`,
//       options: {
//         reconnect: true,
//       },
//     })
//   : null

function getFirebaseUser(): Promise<User | null> {
  return new Promise(resolve => {
    const unsubscribe = auth.onIdTokenChanged(user => {
      resolve(user)
      unsubscribe()
    })
  })
}

const authLink = setContext(async (_, { headers }) => {
  const user = await getFirebaseUser()
  const token = user ? await user.getIdToken() : undefined

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const splitLink = /* process.browser
  ? split(
      ({ query }) => {
        const definition = getMainDefinition(query)
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        )
      },
      wsLink,
      httpLink,
    )
  : */ httpLink

const errorLink = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    if (response.errors?.length > 0) {
      response.errors.forEach(err => {
        if (err.extensions?.code) {
          const translatedError = getTranslatedErrorMessage(
            err.extensions?.code,
          )

          if (translatedError) {
            // eslint-disable-next-line no-param-reassign
            err.message = translatedError
          }
        }
      })
    }
    return response
  })
})

const client = new ApolloClient({
  link: from([authLink, errorLink, splitLink]),
  cache,
})

export default client
