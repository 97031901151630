import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'

import { ENVIRONMENT } from '../app/constants'

const FIREBASE_CONFIG = {
  [ENVIRONMENT.dev]: {
    apiKey: 'AIzaSyDKrnZDwHJsKSjcHfcKV01Jn0EkHNOofag',
    authDomain: 'jobber-project-dev.firebaseapp.com',
    projectId: 'jobber-project-dev',
    storageBucket: 'jobber-project-dev.appspot.com',
    messagingSenderId: '20566021220',
    appId: '1:20566021220:web:6884e5aad2ff73fa4c3b71',
  },
  [ENVIRONMENT.production]: {
    apiKey: 'AIzaSyBsPbaY08EwCxaAR6J3A5F003ugSg1H8-0',
    authDomain: 'jobber-project.firebaseapp.com',
    databaseURL: 'https://jobber-project.firebaseio.com',
    projectId: 'jobber-project',
    storageBucket: 'jobber-project.appspot.com',
    messagingSenderId: '537322740175',
    appId: '1:537322740175:web:09cd4e2fc0e8463f40daba',
    measurementId: 'G-LXBMCM7G48',
  },
}

const firebaseConfig =
  FIREBASE_CONFIG[process.env.NEXT_PUBLIC_ENVIRONMENT] ??
  FIREBASE_CONFIG[ENVIRONMENT.production]

const app = initializeApp(firebaseConfig)

const auth = getAuth(app)

export { auth }
