import PropTypes from 'prop-types'
import NextError from 'next/error'
import { FC } from 'react'
import { appWithTranslation } from 'next-i18next'
import { ThemeProvider } from 'styled-components'
import { ApolloProvider } from '@apollo/client'
import * as Sentry from '@sentry/react'
import { DialogProvider, Toaster } from '@jobber-project/jobber-ui'

import client from '../features/graphql/client'
import GlobalStyle from '../features/app/GlobalStyle'
import Announcement from '../features/app/Announcement'

const theme = {
  colors: {
    primary: '#cccccc',
  },
}

function App({ Component, pageProps }) {
  return (
    <Sentry.ErrorBoundary fallback={() => <NextError statusCode={500} />}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <DialogProvider />
        {typeof document !== 'undefined' && <Toaster />}
        <Announcement />
        <ApolloProvider client={client}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Component {...pageProps} />
        </ApolloProvider>
      </ThemeProvider>
    </Sentry.ErrorBoundary>
  )
}

App.propTypes = {
  Component: PropTypes.elementType,
  pageProps: PropTypes.objectOf(PropTypes.any),
}

App.defaultProps = {
  Component: null,
  pageProps: {},
}

// https://github.com/i18next/next-i18next/issues/1944
export default appWithTranslation(App as FC)
