import { useEffect, useState } from 'react'
import { Button, Checkbox, Text } from '@jobber-project/jobber-ui'
import styled, { keyframes } from 'styled-components'
import Link from 'next/link'

import { COLORS } from '../../utils/colorUtils'
import Spacer from '../../components/Spacer'

const animateIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-50%) matrix3d(1, 0, 0, 0, 0, 1, 0, -0.001, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  100% {
    opacity: 1;
  }
`

const Positioner = styled.div`
  z-index: 999;
  position: fixed;
  top: 20px;
  right: 10px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  background-color: ${COLORS.white};
  border-radius: 8px;
  overflow: hidden;
  box-sizing: border-box;
  padding: 16px 32px;
  border: 1px solid ${COLORS.mischa};
  box-shadow: 0px 5px 50px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  animation: ${animateIn} ease 280ms 300ms forwards;
  pointer-events: all;
  color: ${COLORS.charade};
  text-align: center;
`

const Title = styled(Text)`
  font-size: 1.8rem;
  line-height: 1.4em;
  font-weight: 500;
`

const Message = styled(Text)`
  font-size: 1.4rem;
  line-height: 1.4em;

  margin-top: 1em;
  & + & {
  }

  & a {
    font-weight: 500;
    color: ${COLORS.electricViolet};
  }
`

const Footer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 16px;
  box-sizing: border-box;
`

const key = 'product-rename-shown'

function Announcement() {
  const [visible, setVisible] = useState<boolean>(false)

  const [checked, setChecked] = useState<boolean>(false)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const expired = new Date() > new Date('2023-01-15')
      const hasShown = localStorage.getItem(key)
      if (!hasShown && !expired) {
        setVisible(true)
      }

      if (expired) {
        localStorage.removeItem(key)
      }
    }
  }, [])

  function handleClick() {
    setVisible(false)
    if (checked) {
      localStorage.setItem(key, '1')
    }
  }

  return visible ? (
    <Positioner>
      <Container>
        <Title>Vi har bytt namn!</Title>
        <Message>
          Nu heter vi{' '}
          <Link href="/">
            <a>Workity.se</a>
          </Link>
        </Message>
        <Message>
          Detta påverkar inte din användning något - du kan logga in och använda
          sidan precis som vanligt.
        </Message>
        <div>
          <Spacer minHeight="10px" maxHeight="10px" />
          <Checkbox
            variant="primary"
            label="Visa inte igen"
            checked={checked}
            onChange={() => setChecked(!checked)}
          />
        </div>
        <Footer>
          <Button variant="primary" onClick={handleClick}>
            OK
          </Button>
        </Footer>
      </Container>
    </Positioner>
  ) : null
}

export default Announcement
