import { createGlobalStyle } from 'styled-components'
import { globalStyle } from '@jobber-project/jobber-ui'

import { COLORS } from '../../utils/colorUtils'

const GlobalStyle = createGlobalStyle`
  ${globalStyle}

  html,
  body,
  #__next {
    height: 100%;
  }

  html {
    font-size: 10px;
  }

  body {
    font-size: 1.6rem;
  }

  a {
    color: inherit;
    text-decoration: inherit;
  }

  strong {
    font-weight: 700;
  }

  textarea {
    appearance: none;
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 12px 10px;
    resize: vertical;
    border: 1px solid ${COLORS.black};
    border-radius: 5px;
    box-shadow: none;
    font-family: inherit;
    font-size: 1.2rem;
    line-height: 1.4em;
    color: inherit;

    &::placeholder {
      font-family: inherit;
      font-size: 1.2rem;
      line-height: 1.4em;
      color: ${COLORS.paleSlate};
    }

    &:placeholder-shown {
      border-color: ${COLORS.paleSlate};
    }
  }

  [id^="__lpform"] {
    z-index:0 !important;
  }
`

export default GlobalStyle
