import styled from 'styled-components'

const Container = styled.div<{
  $maxHeight: string
  $minHeight: string
}>`
  height: 100%;
  max-height: ${props => props.$maxHeight};
  ${props => !!props.$minHeight && `min-height: ${props.$minHeight};`}
`

type SpacerProps = {
  maxHeight: string
  minHeight?: string
}

function Spacer({ maxHeight = '100%', minHeight = '0' }: SpacerProps) {
  return <Container $maxHeight={maxHeight} $minHeight={minHeight} />
}

export default Spacer
