import { InMemoryCache, makeVar } from '@apollo/client'

export const isAuthenticating = makeVar(false)

const auth = {
  read() {
    return isAuthenticating()
  },
}

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        auth,
      },
    },
    Section: {
      fields: {
        content: {
          merge(existing, incoming) {
            return incoming
          },
        },
      },
    },
  },
})
